import React from "react";
import Navbar from "../component/Navbar";
import Foodtype from "../component/Foodtype";
import Order from "../component/Order";
import Category from "../component/Category";
import Step from "../component/Step";
import Backimg from "../component/Backimg";
import Bistro from "../component/Bistro";
import Offer from "../component/Offer";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";

const Foodwear = () => {
  return (
    <>
    <Helmet>
       <meta charSet="utf-8" />
       {/* <link rel="icon" type="image/png" href={favicon} sizes="16x16" /> */}
       {/* <link rel="icon" href="./images/favicon.png" /> */}
       <title>Agricuture || order-food-online </title>
     </Helmet>
    
    <div className="px-5">
      <Navbar />
      <Foodtype />
      <Order />
      <Category />
      <Step />
      <Backimg />
      <Bistro />
      <Offer />
      <Footer />
    </div>
    </>
  );
};

export default Foodwear;
