import React from "react";

const Hotest = () => {
  return (
    // bottom-[-700px] xl:bottom-[-100px] sm:bottom-[-80px] md:bottom-[-400px]  lg:bottom-[-400px]
   // md:pt-[150px] lg:pt-[150px] xl:pt-[150px]  pt-[800px] w-fit xl:w-[1200px] mx-auto
    <div class="md:pt-[150px] lg:pt-[150px] xl:pt-[150px]  pt-[800px] w-fit xl:w-[1200px] mx-auto">
      <div class="container">
        <div class=" flex flex-wrap items-center">
          <div class="md:w-[50%] w-[100]">
            <div class="titlepage">
              <h2 className="text-5xl font-bold leading-snug text-center">
                World’s Hottest Destinations <br />
                for Vegans
              </h2>
            </div>
          </div>
          <div class="md:w-[50%] w-[100%]">
            <div class="bg-Lightyeloow text-white py-14 px-10">
              <p className="text-xl">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend to repeat predefined There are many variations
                of passages of Lorem Ipsum available, but the majority have
                suffered alteration in some form, by injected humour, or
                randomised words which don't look even slightly believable. If
                you are going to use a passage of Lorem Ipsum, you need to be
                sure there isn't anything embarrassing hidden in the middle of
                text. All the Lorem Ipsum generators on the Internet tend to
                repeat predefined{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hotest;
