import React from "react";
import uper from "../assets/uper.png";
import bistro from "../assets/bistro.png";
import walt from "../assets/walt.png";

const Bistro = () => {
  return (
    <>
      <div class="container8 mt-9">
        <div class="colortext2 flex justify-between items-center pl-[17%] pr-[5%] items-endmt-[42px]">
          <div class="simple">
            <h3 className="text-xl font-semibold">Our Bistro</h3>
            <h2 className="cate text-2xl font-bold text-pinkiess mt-3 mb-8">
              Locations
            </h2>
          </div>
          <h5 class="vbtn2 text-pinkiess font-[15px]">
            All contact details --
          </h5>
        </div>

        <div class="maptext w-[80%] flex flex-wrap md:flex-nowrap justify-center gap-5 items-center ml-[15%]">
          <div class="map">
            <iframe
              className="ifrm "
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d117763.55154192758!2d75.79380968095644!3d22.724115838226357!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fcad1b410ddb%3A0x96ec4da356240f4!2sIndore%2C%20Madhya%20Pradesh!5e0!3m2!1sen!2sin!4v1674046674196!5m2!1sen!2sin"
              width="full"
              height="500"
              style={{ border: 0  }}
            ></iframe>
          </div>
          <div class="secondmain">
            <div class="maptexta">
              <h1 className="text-3xl font-bold">Bistro - Downtown</h1>
              <p class="pera border-b-2 pb-[5px] text-black mt-4">
                Etiam nibh neque, aliquam ut ornare in, porttitor eu quam.
                Maecenas magna dui, efficitur ut laoreet vel, euismod eget erat
                vestibulum.
              </p>
              <p class="pera2 mt-3">
                <span className="text-lg font-bold">Address:</span> 1400 14th St
                NW, Washington, DC 20005
              </p>
            </div>
            <div class="maptexta mt-4">
              <h1 className="text-3xl font-bold">Bistro - Capitol</h1>
              <p class="pera mt-3">
                Nunc congue nibh lorem, viverra sodales sapien ullamcorper eu.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit
                maecenas.
              </p>
              <hr></hr>
              <p class="pera2 mt-3">
                <span className="text-lg font-bold">Address:</span> 501 E
                Capitol St SE, Washington, DC 20003
              </p>
            </div>
            <div class="maptexta mt-4">
              <h1 className="text-3xl font-bold">Bistro Anacostia</h1>
              <p class="pera mb-3 mt-3">
                Vivamus pharetra efficitur elit sed pulvinar. Praesent est
                risus, porta vel orci nec, vehicula suscipit tortor. Morbi et
                sapien nunc.
              </p>
              <hr></hr>
              <p class="pera2 mt-3">
                <span className="text-lg font-bold">Address:</span> 1400 Good
                Hope Rd SE, Washington, DC 20050
              </p>
            </div>
          </div>
        </div>

        <div class="textimg flex flex-wrap md:justify-evenly justify-center items-center w-[86%] mt-[100px]">
          <div class="firsttext w-[230px] items-justify">
            <h3 className="text-lg font-bold">
              You can also find us on your favorite{" "}
              <span class="service text-pinkiess">delivery service</span>
            </h3>
          </div>
          <div class="imgs flex gap-10">
            <div class="uber">
              <img src={bistro} width="100%" height="100%" alt="" />
            </div>
            <div class="uber">
              {" "}
              <img src={uper} width="100%" height="100%" alt="" />
            </div>
            <div class="uber">
              {" "}
              <img src={walt} width="100%" height="100%" alt="" />
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default Bistro;
