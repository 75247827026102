import React from "react";

const Footer = () => {
  return (
    <>
      <div>
        <div className="container10 py-7 flex flex-wrap mt-4 justify-center items-center w-[100%] bg-pinkiess">
          <div className="centertext md:w-[50%] w-[100%] flex md:justify-center justify-start p-2">
            <h1 className="text-white text-4xl  font-bold items-center">
              +012-345-6789
            </h1>
          </div>
          <div className="sidetext md:w-[50%] w-[100%] justify-between p-2">
            <h4 className="text-black mt-[5px] font-bold mb-3">
              Bistro at new location opened
            </h4>
            <p>
              Excepteur sint occaecat cupidatat non proident, sunt in culpa ut
              aliquid ex ea commodi consequatur? Quis autem vel eum iure
              reprehenderit qui in ea voluptate.
            </p>
          </div>
        </div>
      </div>
      <div className="footer ">
        <ul className=" flex justify-between items-center pt-[100px] pb-[100px] pl-[100px] pr-[100px] flex-wrap">
          {" "}
          <li>Order</li>
          <li>About</li>
          <li>News</li>
          <li>Contact & Reservations</li>
        </ul>
      </div>
    </>
  );
};

export default Footer;
