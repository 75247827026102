import React from "react";
import Slider from "react-slick";

import Cactus from "../assets/plantsSlider/Cactus.png";
import indoorPlants from "../assets/plantsSlider/indoorPlants.png";
import OfficeEnergizers from "../assets/plantsSlider/OfficeEnergizers.png";
import Orchids from "../assets/plantsSlider/Orchids.png";
import OutdoorPlants from "../assets/plantsSlider/OutdoorPlants.png";

const PlantsSlider = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };
  return (
    <>
      <div className="my-12 max-w-[1240px] mx-auto">
        <Slider {...settings}>
          {/* <div className="h-52 md:h-90 px-8"> */}
          <div className="relative bg-Lightpink rounded-t-lg">
            <div className="flex items-center justify-evenly">
              <img
                src={Cactus}
                alt="img"
                className="md:w-40 md:h-40 mt-10 md:mx-4 px-2 w-40 h-40 md:mt-0 scale-100 hover:scale-125 ease-out duration-500"
              />
              <div>
                indoor Plants
                <div> 5 items </div>
              </div>
            </div>
          </div>
          {/* </div> */}
          {/* <div className="h-52 md:h-90 px-8"> */}
          <div className="relative bg-Lightgreen  rounded-t-lg">
            <div className="flex items-center justify-evenly">
              <img
                src={indoorPlants}
                alt="img"
                className="md:w-40 md:h-40 mt-10 md:mx-4 px-2 w-40 h-40 md:mt-0 scale-100 hover:scale-125 ease-out duration-500"
              />
              <div>
                Cactus
                <div> 5 items </div>
              </div>
            </div>
          </div>
          {/* </div> */}
          {/* <div className="h-52 md:h-90 px-8"> h-[140px] md:h-[200px] */}
          <div className="relative bg-Lightblue rounded-t-lg">
            <div className="flex items-center justify-evenly">
              <img
                src={OfficeEnergizers}
                alt="img"
                className="md:w-40 md:h-40 mt-10 md:mx-4 px-2 w-40 h-40 md:mt-0 scale-100 hover:scale-125 ease-out duration-500"
              />
              <div>
                OfficeEnergizers
                <div> 5 items </div>
              </div>
            </div>
          </div>
          {/* </div> */}
          {/* <div className="h-52 md:h-90 px-8"> */}
          <div className="relative bg-sky-500/100 rounded-t-lg">
            <div className="flex items-center justify-evenly">
              <img
                src={Orchids}
                alt="img"
                className="md:w-40 md:h-40 mt-10 md:mx-4 px-2 w-40 h-40 md:mt-0 scale-100 hover:scale-125 ease-out duration-500"
              />
              <div>
                Orchids
                <div> 5 items </div>
              </div>
            </div>
          </div>
          {/* </div> */}
          {/* <div className="h-52 md:h-90 px-8"> */}
          <div className="relative bg-sky-500/100 rounded-t-lg">
            <div className="flex items-center justify-evenly">
              <img
                src={OutdoorPlants}
                alt="img"
                className="md:w-40 md:h-40 mt-10 md:mx-4 px-2 w-40 h-40 md:mt-0 scale-100 hover:scale-125 ease-out duration-500"
              />
              <div>
                Orchids
                <div> 5 items </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </Slider>
      </div>
    </>
  );
};

export default PlantsSlider;
