import React from "react";
import noodles1 from "../assets/noodles1.png";
import waffles2 from "../assets/waffles2.png";
import donuts3 from "../assets/donuts3.png";
import soup4 from "../assets/soup4.png";

const Foodtype = () => {
  return (
    <>
      <div className="flex justify-between flex-wrap pr-[15%] mt-[30px]">
        <div className="foodbox">
          <div className="forimg">
            <img src={noodles1} height="100%" width="100%" alt="" />
          </div>
          <h3 className="text-xl font-bold">Maze-Men</h3>
          <p>$13.50</p>
        </div>
        <div className="foodbox">
          <div className="forimg">
            <img src={waffles2} height="100%" width="100%" alt="" />
          </div>
          <h3 className="text-xl font-bold">Belgian Waffles</h3>
          <p>$12.00</p>
        </div>
        <div className="foodbox">
          <div className="forimg">
            <img src={donuts3} height="100%" width="100%" alt="" />
          </div>
          <h3 className="text-xl font-bold">Doughnuts</h3>
          <p>$3.00</p>
        </div>
        <div className="foodbox">
          <div className="forimg">
            <img src={soup4} height="100%" width="100%" alt="" />
          </div>
          <h3 className="text-xl font-bold">Hokkaido</h3>
          <p>$10.00</p>
        </div>
      </div>
      <h2 className="head text-left items-start mt-[100px] md:pl-[15%] pl-0   font-bold text-xl">
        Order your favorites
      </h2>
    </>
  );
};

export default Foodtype;
