import React from "react";
import boximg1 from "../assets/images/boximg1.jpg";
import box2 from "../assets/images/box2.jpg";
import box3 from "../assets/images/box3.jpg";
const Banner = () => {
  // text-transform: uppercase;
  // color: #fff;
  // font-size: 110px;
  // line-height: 120px;
  // padding-bottom: 15px;
  // font-weight: bold;
  return (
    <div>
      {" "}
      <section className="bg-Bannerimg relative h-[840px]  bg-no-repeat items-center flex  justify-center">
        <div className="container  ">
          <div className="row">
            <div className="col-md-12 ">
              <div className="uppercase text-white pb-[15px]  mx-auto   w-[80%]">
                <h1 className="md:text-[110px] text-[50px] items-center  font-bold text-center max-w-fit">
                  FARMING COMPANY
                </h1>
                <p className="pb-[50px] max-w-fit items-center">
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  The point of using Lorem Ipsum is that it has a more-or-less
                  normal distribution of letters, as opposed to using 'Content
                  here, content here', making it
                </p>
                <button className="mx-auto text-lg block rounded-full border-4 border-Yellow py-3 px-16 hover:bg-black transition delay-150  ease-in-out">
                  Discover
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* three box imgs */}
        <div className="absolute bottom-[-700px] xl:bottom-[-100px] sm:bottom-[-80px] md:bottom-[-400px]  lg:bottom-[-400px]">
          <div className="flex flex-wrap justify-center  gap-8">
            <div className="">
              <figure>
                <img src={boximg1} alt="#" />
              </figure>
            </div>
            <div className="">
              <figure>
                <img src={box2} alt="#" />
              </figure>
            </div>
            <div className="">
              <figure>
                <img src={box3} alt="#" />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Banner;
