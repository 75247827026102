import React from "react";
import offer1 from "../assets/offer1.png";
import offer2 from "../assets/offer2.png";
import offer3 from "../assets/offer3.png";

const Offer = () => {
  return (
    <>
      <div className="container9 mt-16 md:h-[500px]  bg-cover bg-no-repeat  bg-offerbgimg">
        <div className="centerdiv pl-[16%]">
          <div className="headtext">
            <h2 className="text-2xl font-bold">Special Offers on</h2>
            <h1 className="text-3xl mt-3 font-bold text-pinkiess">
              Fresh Additions
            </h1>
          </div>

          <div className="saleimages gap-5 mt-6 flex flex-wrap">
            <div className="sale w-[180px] h-[170px] rounded-[5px] ml-[3px]">
              <img src={offer1} width="100%" height="100%" alt="" />
            </div>
            <div className="sale w-[180px] h-[170px] rounded-[5px] ml-[3px]">
              {" "}
              <img src={offer2} width="100%" height="100%" alt="" />
            </div>
            <div className="sale w-[180px] h-[170px] rounded-[5px] ml-[3px]">
              <img src={offer3} width="100%" height="100%" alt="" />
            </div>
          </div>
        </div>

        <div className="textinsert ">
          <div className="linea mt-[70px] flex-wrap  flex justify-center md:w-[520px] w-fit pl-[13%]">
            <p className="color text-pinkiess ">ORDER BY PHONE</p>
            <p className="ml-2">DON T MISS OUT</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Offer;
