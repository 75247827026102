import React from "react";

const CtnButhon = () => {
  return (
    <div
      className="fixed z-20 top-1/2 right-[-4.55rem] bg-Green
  p-1 px-2 justify-end items-end rotate-90 rounded-bl-3xl rounded-br-3xl text-white"
    >
      <span className="uppercase font-heading text-base cursor-pointer ">
        Get a Free quote
      </span>
    </div>
  );
};

export default CtnButhon;
