import React from "react";
import order1img from "../assets/order1img.png";
import order2img from "../assets/order2img.png";
import order3img from "../assets/order3img.png";
import order4img from "../assets/order4img.png";
import order5img from "../assets/order5img.png";
import order6img from "../assets/order6img.png";
import order7img from "../assets/order7img.png";
import order8img from "../assets/order8img.png";
import order9img from "../assets/order9img.png";

const Order = () => {
  return (
    <>
      {/* <div className="container3 flex justify-between flex-wrap ">
        <div className="bg-orderbgimg bg-cover position-center relative">
          <div className="backbox h-[50%] mt-[50px]">
            <h4>CATEGRIOES</h4>
            <div className="buttons flex flex-wrap w-[230px] mt-[10px] items-start">
              <button className="btnn px-3 bg-pinkiess text-white items-center border-0 rounded-[5px] mt-[3px] h-[30px] ml-[3px] w-fit">
                Burgers
              </button>
              <button
                className="btnn px-3  bg-pinkiess text-white items-center border-0 rounded-[5px] 
              mt-[3px] h-[30px] ml-[3px] w-fit"
              >
                Fish
              </button>
              <button
                className="btnn px-3 bg-pinkiess text-white items-center border-0 rounded-[5px] 
              mt--[3px] h-[30px] ml-[3px] w-fit"
              >
                Meat
              </button>
              <button
                className="btnn px-3 bg-pinkiess text-white items-center border-0 rounded-[5px] 
              mt-[3px] h-[30px] ml-[3px] w-fit"
              >
                Noodles
              </button>
              <button
                className="btnn px-3  bg-pinkiess text-white items-center border-0 rounded-[5px]
               mt-[3px] h-[30px] ml-[3px] max-w-fit"
              >
                Pastries
              </button>
              <button
                className="btnn px-3 bg-pinkiess text-white items-center border-0 rounded-[5px] 
              mt-[3px] h-[30px] ml-[3px] w-fit"
              >
                Salad
              </button>
              <button
                className="btnn px-3  bg-pinkiess text-white items-center border-0 rounded-[5px] 
              mt-[3px] h-[30px] ml-[3px] w-fit"
              >
                Soups
              </button>
            </div>
            <h5>DIET TYPE</h5>
            <div className="inputs">
              <div className="checkbox flex justify-around w-[140px] items-center bg-[rgba(34, 34, 34, 0.03)] h-[30px] mt-[3px]">
                <input type="checkbox " name="" id="" /> <p>Gluten Free(9 )</p>{" "}
              </div>
              <div className="checkbox flex justify-around w-[140px] items-center bg-[rgba(34, 34, 34, 0.03)] h-[30px] mt-[3px]">
                <input type="checkbox" name="" id="" /> <p>Vegan(8 )</p>
              </div>
              <div className="checkbox flex justify-around w-[140px] items-center bg-[rgba(34, 34, 34, 0.03)] h-[30px] mt-[3px]">
                <input type="checkbox" name="" id="" /> <p>Vegetarian(16 )</p>{" "}
              </div>
            </div>
            <p>price</p>
            <div className="design h-[10px] w-[80%]bg-pinkiess"></div>

            <div className="price flex justify-between w-[180px] mt-[10px]">
              <div className="a w-[45%] bg-white">$0.00</div>
              <div className="a w-[45%] bg-white">$30.00</div>
            </div>
          </div>
        </div>

        <div className="foodboxes w-[60%] flex flex-wrap justify-between">
          <div className="food1">
            <div className="forimg2 w-[200px] h-[180px] mt-[50px]">
              <img src={order1img} width="100%" height="100%" alt="" />{" "}
            </div>
            <h3>Grilled Eggplant Salad</h3>
            <p>$14.00</p>
          </div>
          <div className="food1">
            <div className="forimg2 w-[200px] h-[180px] mt-[50px]">
              <img src={order2img} width="100%" height="100%" alt="" />{" "}
            </div>
            <h3>Vegan Burger</h3>
            <p>$12.00</p>
          </div>
          <div className="food1">
            <div className="forimg2 w-[200px] h-[180px] mt-[50px]">
              <img src={order3img} width="100%" height="100%" alt="" />{" "}
            </div>
            <h3>Fish & Chips</h3>
            <p>$13.00</p>
          </div>
          <div className="food1">
            <div className="forimg2 w-[200px] h-[180px] mt-[50px]">
              <img src={order4img} width="100%" height="100%" alt="" />{" "}
            </div>
            <h3>Smoothie</h3>
            <p>$6.00 $4.00</p>
          </div>
          <div className="food1">
            <div className="forimg2 w-[200px] h-[180px] mt-[50px]">
              <img src={order5img} width="100%" height="100%" alt="" />{" "}
            </div>
            <h3>Poke Salad</h3>
            <p>$11.00</p>
          </div>
          <div className="food1">
            <div className="forimg2 w-[200px] h-[180px] mt-[50px]">
              <img src={order6img} width="100%" height="100%" alt="" />{" "}
            </div>
            <h3>Sandwiched Burger</h3>
            <p>$11.00</p>
          </div>
          <div className="food1">
            <div className="forimg2 w-[200px] h-[180px] mt-[50px]">
              <img src={order7img} width="100%" height="100%" alt="" />{" "}
            </div>
            <h3>Miso Ramen</h3>
            <p>$14.00</p>
          </div>
          <div className="food1">
            <div className="forimg2 w-[200px] h-[180px] mt-[50px]">
              <img src={order8img} width="100%" height="100%" alt="" />{" "}
            </div>
            <h3>Citrus Crepes</h3>
            <p>$12.00</p>
          </div>
          <div className="food1">
            <div className="forimg2 w-[200px] h-[180px] mt-[50px]">
              {" "}
              <img src={order9img} width="100%" height="100%" alt="" />
            </div>
            <h3>Roasted Pork Belly</h3>
            <p>$17.00</p>
          </div>
        </div>
      </div> */}
      <div className="container3 flex flex-wrap md:flex-nowrap justify-between   flex-row pr-[15%]">
        <div className="main md:w-[40%] w-[100%] bg-orderbgimg flex justify-end ">
          <div className="backbox h-[50%] mt-[50px]">
            <h4 className="text-xl font-bold">CATEGRIOES</h4>
            <div className="buttons flex flex-wrap w-[230px] mt-[10px] items-start">
              <button className="btnn bg-pinkiess text-white items-center border-none text-base rounded-[5px] p-[3px] mt-[3px] h-[30px ml-[3px] ">
                Burgers
              </button>
              <button className="btnn bg-pinkiess text-white items-center border-none text-base rounded-[5px] p-[3px] mt-[3px] h-[30px ml-[3px] ">
                Fish
              </button>
              <button className="btnn bg-pinkiess text-white items-center border-none text-base rounded-[5px] p-[3px] mt-[3px] h-[30px ml-[3px]">
                Meat
              </button>
              <button className="btnn bg-pinkiess text-white items-center border-none text-base rounded-[5px] p-[3px] mt-[3px] h-[30px ml-[3px] ">
                Noodles
              </button>
              <button className="btnn bg-pinkiess text-white items-center border-none text-base rounded-[5px] p-[3px] mt-[3px] h-[30px ml-[3px] ">
                Pastries
              </button>
              <button className="btnn bg-pinkiess text-white items-center border-none text-base rounded-[5px] p-[3px] mt-[3px] h-[30px ml-[3px] ">
                Salad
              </button>
              <button className="btnn bg-pinkiess text-white items-center border-none text-base rounded-[5px] p-[3px] mt-[3px] h-[30px ml-[3px] ">
                Soups
              </button>
            </div>
            <h5 className="text-lg font-bold mt-5">DIET TYPE</h5>
            {/* input class nhi mili */}
            <div className="inputs">
              <div className="checkbox flex  w-[140px] items-center justify-start bg-darkgry h-[30px] mt-[3px]">
                <input type="checkbox" name="" id="" /> <p className="ml-2">Gluten Free(9 )</p>{" "}
              </div>
              <div className="checkbox flex  w-[140px] items-center justify-start bg-darkgry h-[30px] mt-[3px]">
                <input type="checkbox" name="" id="" /> <p className="ml-2">Vegan(8 )</p>
              </div>
              <div className="checkbox flex  w-[140px] items-center justify-start bg-darkgry h-[30px] mt-[3px]">
                <input type="checkbox" name="" id="" /> <p className="ml-2">Vegetarian(16 )</p>{" "}
              </div>
            </div>
            <p>price</p>
            <div className="design h-[10px] w-[80%] bg-pinkiess"></div>

            <div className="price flex justify-between w-[180px] mt-[10px]">
              <div className="a w-[45%] bg-white">$0.00</div>
              <div className="a w-[45%] bg-white">$30.00</div>
            </div>
          </div>
        </div>

        <div className="foodboxes flex flex-wrap flex-row justify-between md:w-[60%] w-[100%] items-center p-0 mt-[20px]">
          <div className="food1">
            <div className="forimg2 w-[200px] h-[180px] mt-[50px]">
              <img src={order1img} width="100%" height="100%" alt="" />{" "}
            </div>
            <h3 className="text-xl font-bold">Grilled Eggplant Salad</h3>
            <p>$14.00</p>
          </div>
          <div className="food1">
            <div className="forimg2  w-[200px] h-[180px] mt-[50px]">
              <img src={order2img} width="100%" height="100%" alt="" />{" "}
            </div>
            <h3 className="text-xl font-bold">Vegan Burger</h3>
            <p>$12.00</p>
          </div>
          <div className="food1">
            <div className="forimg2  w-[200px] h-[180px] mt-[50px]">
              <img src={order3img} width="100%" height="100%" alt="" />{" "}
            </div>
            <h3 className="text-xl font-bold">Fish & Chips</h3>
            <p>$13.00</p>
          </div>
          <div className="food1">
            <div className="forimg2  w-[200px] h-[180px] mt-[50px]">
              <img src={order4img} width="100%" height="100%" alt="" />{" "}
            </div>
            <h3 className="text-xl font-bold">Smoothie</h3>
            <p>$6.00 $4.00</p>
          </div>
          <div className="food1">
            <div className="forimg2  w-[200px] h-[180px] mt-[50px]">
              <img src={order5img} width="100%" height="100%" alt="" />{" "}
            </div>
            <h3 className="text-xl font-bold">Poke Salad</h3>
            <p>$11.00</p>
          </div>
          <div className="food1">
            <div className="forimg2  w-[200px] h-[180px] mt-[50px]">
              <img src={order6img} width="100%" height="100%" alt="" />{" "}
            </div>
            <h3 className="text-xl font-bold">Sandwiched Burger</h3>
            <p>$11.00</p>
          </div>
          <div className="food1">
            <div className="forimg2  w-[200px] h-[180px] mt-[50px]">
              <img src={order7img} width="100%" height="100%" alt="" />{" "}
            </div>
            <h3 className="text-xl font-bold">Miso Ramen</h3>
            <p>$14.00</p>
          </div>
          <div className="food1">
            <div className="forimg2  w-[200px] h-[180px] mt-[50px]">
              <img src={order8img} width="100%" height="100%" alt="" />{" "}
            </div>
            <h3 className="text-xl font-bold" >Citrus Crepes</h3>
            <p>$12.00</p>
          </div>
          <div className="food1">
            <div className="forimg2  w-[200px] h-[180px] mt-[50px]">
              {" "}
              <img src={order9img} width="100%" height="100%" alt="" />
            </div>
            <h3 className="text-xl font-bold">Roasted Pork Belly</h3>
            <p>$17.00</p>
          </div>
          {/* <div className="next ml-[420px] mt-[40px] flex justify-between">
            <button className="btna text-white bg-pinkiess">1</button>
            <button>2</button>
            <button>3</button>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Order;
