import React from 'react'
import foodbackimg from "../assets/foodbackimg.png"

const Backimg = () => {
  return (
   <>
    <div className="container7 w-[100%]  mt-8">

<img src={foodbackimg} width="100%" height="100%" alt="" />

   </div></>
  )
}

export default Backimg