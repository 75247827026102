import React from "react";

const Step = () => {
  return (
    <>
      <div className="container6 justify-center">
        <div className="text1 mt-[50px] flex flex-wrap md:flex-nowrap justify-around items-center pl-[10%] pr-[5%]">
          <div className="step md:w-[20%] w-[100%]">
            <h3 className="text-xl font-bold">How does it work?</h3>
            <h1 className="text-pinkiess text-4xl mt-3 font-bold">
              Step-by-step
            </h1>
          </div>
          <div className="para md:w-[60%] w-[100%] text-justify mt-[20px]">
            Sed venenatis nibh nisl, id efficitur augue sodales sed. Nam nec
            efficitur turpis. Orci varius natoque penatibus et magnis dis
            parturient montes, nascetur ridiculus mus. Curabitur sed leo vel
            diam vehicula sagittis. Praesent ornare sapien a arcu imperdiet.
          </div>
        </div>

        <div className="points mt-7 flex flex-wrap md:flex-nowrap pr-[9%] pl-[13.9%]">
          <div className="point1 mt-2">
            <h1 className="text-Gray text-2xl">01.</h1>
            <h2 className="text-xl font-bold mt-3">Choose your food</h2>
            <p className="mt-3">
              Select from wide range of meals or treats or both, no judgments :
            </p>
          </div>
          <div className="point1 mt-2">
            <h1 className="text-Gray text-2xl">02.</h1>
            <h2 className="text-xl font-bold mt-3">Delivery or takeout</h2>
            <p className="mt-3">
              Have your food delivered or pick it up yourself at one of our
              bistros
            </p>
          </div>
          <div className="point1 mt-2">
            <h1 className="text-Gray text-2xl">03.</h1>
            <h2 className="text-xl font-bold mt-3">Enjoy the food your way</h2>
            <p className="mt-3">
              Wherever it tastes better for you, however you are always welcome
              at our bistro!
            </p>
          </div>
        </div>

        <div className="newbtn mt-[70px] flex justify-center md:w-[500px] w-fit pl-[13%]">
          <button className="btn_o bg-pinkiess border-0 w-[120px] h-[35px] rounded-[3px] text-white items-center">
            Order Now
          </button>
          <button className="btn_b  border-0 w-[120px] h-[35px] rounded-[3px] text-black bg-Gray items-center ml-3">
            Bisatro Locations
          </button>
        </div>
      </div>
    </>
  );
};

export default Step;
