import React from "react";
import footerimg from "../assets/images/footerimg.png";

const Footer = () => {
  return (
    <>
      <div className="mt-28 ">
        <div className="bg-bluedark py-10 px-6">
          <div className="flex flex-wrap md:flex-nowrap justify-center gap-20  w-fit xl:w-[1200px] mx-auto">
            <div className="w-[33%] text-white text-center">
              <h2 className="text-3xl pb-4">About</h2>
              <p className="text-base">
                CERBOSYS is a software development company that provides
                customized and creative services for online businesses. The
                services we provide have been instrumental in helping shape
                several start-up companies by meeting their changing needs.
              </p>
            </div>
            <div className="w-[33%] text-white text-center">
              <h2 className="text-3xl pb-4">Address</h2>
              <p className="text-base">
                Address: 15-16 Lakshya Vihar Indore
              </p>{" "}
              <p className="text-base"> Tel: 0731 499 3444</p>{" "}
              <p className="text-base">website: www.cerbosys.com</p>
            </div>
            <div className="w-[33%]">
              <img src={footerimg} alt="" />
            </div>
          </div>
        </div>
        <div className="bg-sky py-5 text-center">
            <p className="text-lg text-white">© 2019 All Rights Reserved. Design by Cerbosys</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
