import React from "react";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import Hotest from "../components/Hotest";
import Chooseus from "../components/Chooseus";
import OurProduct from "../components/OurProduct";
import About from "../components/About";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
const Agricuture = () => {
  return (
    <>
     <Helmet>
        <meta charSet="utf-8" />
        {/* <link rel="icon" type="image/png" href={favicon} sizes="16x16" /> */}
        {/* <link rel="icon" href="./images/favicon.png" /> */}
        <title>Agricuture || Farming </title>
      </Helmet>

    <div>
      <Navbar />
      <Banner />
      <Hotest />
      <Chooseus />
      <OurProduct />
      <About />
      <Footer />
    </div>    </>
  );
};

export default Agricuture;
