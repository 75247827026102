import React from "react";
import foodimg from "../assets/images/foodimg.jpg";
import icon2 from "../assets/images/icon2.png";
import icon1 from "../assets/images/icon1.png";
import icon3 from "../assets/images/icon3.png";
import icon4 from "../assets/images/icon4.png";

const Chooseus = () => {
  return (
    <div class="mt-11 ">
      <div className="bg-darkgry text-white h-auto pt-[90px] pb-[170px]   relative">
        <div className=" w-fit xl:w-[1200px] mx-auto py-12 p-3">
          <div className="flex flex-wrap md:flex-nowrap items-center gap-9 w-fit xl:w-[1200px] mx-auto">
            {/* <div class=" w-fit xl:w-[1200px] mx-auto"> 
            bottom-[-700px] xl:bottom-[-100px] sm:bottom-[-80px] md:bottom-[-400px]  lg:bottom-[-400px]*/}

            <div class="row d_flex">
              <div class="titlepage">
                <h3 className="font-semibold text-5xl">Why Choose Us? </h3>
                <p className="pt-6 text-lg pb-8">
                  there isn't anything embarrassing hidden in the middle of
                  text. All the Lorem Ipsum generators on the Internet tend to
                  repeat predefined{" "}
                </p>
              </div>
              <div class="padding_with mt-8">
                <div class="flex flex-wrap md:flex-nowrap">
                  <div class="choose_box flex">
                    <div className="w-25 mr-3 bg-Yellow h-12  rounded-full flex justify-center items-center">
                      {" "}
                      <i>
                        <img src={icon2} alt="#" className="w-[100px] p-2" />
                      </i>
                    </div>
                    <div class="choose_text">
                      <h3 className="font-semibold text-xl">
                        Excellent Service
                      </h3>
                      <p className="mt-3">
                        It is a long established fact that a reader will be
                        distracted by the readable content of a
                      </p>
                    </div>
                  </div>

                  <div class="choose_box flex ">
                    <div className="w-25 mr-3 bg-Yellow h-12  rounded-full flex justify-center items-center">
                      {" "}
                      <i>
                        <img src={icon1} alt="#" className="w-[100px] p-2" />
                      </i>
                    </div>
                    <div class="choose_text">
                      <h3 className="font-semibold text-xl">Clean Working</h3>
                      <p className="mt-3">
                        It is a long established fact that a reader will be
                        distracted by the readable content of a
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex flex-wrap md:flex-nowrap mt-5">
                    <div class="choose_box flex">
                      <div className="w-25 mr-3 bg-Yellow h-12  rounded-full flex justify-center items-center">
                        {" "}
                        <i>
                          <img src={icon3} alt="#" className="w-[100px] p-2" />
                        </i>
                      </div>
                      <div class="choose_text">
                        <h3 className="font-semibold text-xl">
                          Quality And Reliability
                        </h3>
                        <p className="mt-3">
                          It is a long established fact that a reader will be
                          distracted by the readable content of a
                        </p>
                      </div>
                    </div>

                    <div class="choose_box flex">
                      <div className="w-25 mr-3 bg-Yellow h-12  rounded-full flex justify-center items-center">
                        <i>
                          <img src={icon4} alt="#" className="w-[100px] p-2" />
                        </i>
                      </div>
                      <div class="choose_text">
                        <h3 className="font-semibold text-xl">Expert Farmer</h3>
                        <p className="mt-3">
                          It is a long established fact that a reader will be
                          distracted by the readable content of a
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-9">
                    <button className="bg-Yellow py-3 px-9 hover:bg-Gray block">
                      Read More
                    </button>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
            {/* </div> */}
            <div class="container bg-white  border-[18px] border-white border-l-0">
              <figure>
                <img src={foodimg} alt="img" className="w-full  " />
              </figure>
            </div>
          </div>
        </div>
        <div className="bg-Bgdownimg absolute bottom-0 left-0 h-[195px]  right-0 w-full bg-no-repeat block bg-center"></div>
      </div>
    </div>
  );
};

export default Chooseus;
