import React from "react";
import Navbar from "../components/Navbar";
import Home from "../pages/Home";
import PlantsSlider from "../pages/PlantsSlider";
import Cart from "../pages/Cart";
import CtnButhon from "../pages/CtnButhon";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

const Plants = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        {/* <link rel="icon" type="image/png" href={favicon} sizes="16x16" /> */}
        {/* <link rel="icon" href="./images/favicon.png" /> */}
        <title>Agricuture || Plants </title>
      </Helmet>
      <div>
        {" "}
        <Navbar />
        <Home />
        <PlantsSlider />
        <Cart />
        <CtnButhon />
        <Footer />
      </div>
    </>
  );
};

export default Plants;
