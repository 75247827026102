import React from "react";
import productimg1 from "../assets/images/productimg1.jpg";
import productimg2 from "../assets/images/productimg2.jpg";
import productimg3 from "../assets/images/productimg3.jpg";
import productimg4 from "../assets/images/productimg4.jpg";
import productimg5 from "../assets/images/productimg5.jpg";

const OurProduct = () => {
  return (
    <>
      <div className="mt-4 P-4">
        <div className="">
          <h2 className="font-semibold text-5xl pl-8"> Our Product</h2>
          <div className="mt-12 flex flex-wrap md:flex-nowrap gap-7">
            <div className="grow relative">
              {" "}
              <img src={productimg1} alt="img" />
              <h2 className=" text-2xl absolute bottom-4 right-4">
               VEGETABLE
              </h2>
            </div>
            <div className="relative">
              {" "}
              <img src={productimg2} alt="img" />
              <h2 className="text-white text-2xl absolute bottom-4 right-4">
                WEAT
              </h2>
            </div>
            <div className="relative">
              {" "}
              <img src={productimg3} alt="img" />
              <h2 className="text-white text-2xl absolute bottom-4 right-4">
                FRUIT
              </h2>
            </div>
          </div>
          <div className="mt-6 flex flex-wrap md:flex-nowrap gap-7">
            <div className="grow relative">
              {" "}
              <img src={productimg4} alt="img" />
              <h2 className="text-white text-2xl absolute bottom-4 right-4">
               SUNFLOWER
              </h2>
            </div>
            <div className="relative">
              {" "}
              <img src={productimg5} alt="img" />
              <h2 className="text-white text-2xl absolute bottom-4 right-4">
                LIVESTOCK
              </h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurProduct;
