import React from "react";
import logo from "../assets/images/logo.png";
import { AiOutlineSearch } from "react-icons/ai";
import { FaBars } from "react-icons/fa";

const Navbar = () => {
  return (
    // <div className="container mx-auto">
    <div className="flex justify-between  items-center text-white bg-black p-8">
      {/* <div className="container mx-auto"> */}
      <div className="w-[40%] ">
        {/* <div className="container mx-auto"> */}
        <div>
          <img src={logo} alt="img" className="cursor-pointer" />
        </div>
        {/* </div> md:visible hidden */}
      </div>
      <div className="w-[60%] lg:visible invisible">
        <div className=" w-full ">
          <ul className=" flex justify-between  items-center">
            <li className="cursor-pointer hover:text-Yellow ">HOME</li>
            <li className="cursor-pointer hover:text-Yellow">ABOUT</li>
            <li className="cursor-pointer hover:text-Yellow">PRODUCTS</li>
            <li className="cursor-pointer hover:text-Yellow">BLOG</li>
            <li className="cursor-pointer hover:text-Yellow">CONTACT</li>
            <li className="cursor-pointer font-bold text-2xl">
              <AiOutlineSearch />
            </li>
            <li>
              <button className="bg-Yellow py-3 px-9 hover:bg-Gray">
                GET A QUOTE{" "}
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="lg:invisible visible">
        <div>
          <FaBars />
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Navbar;
