import React from "react";
import category1 from "../assets/category1.png";
import category2 from "../assets/category2.png";
import caterogy3 from "../assets/caterogy3.png";
import category5 from "../assets/category5.png";
import caterogy6 from "../assets/caterogy6.png";
import caterogy7 from "../assets/caterogy7.png";
// import category1 from "../assets/category1.png";

const Category = () => {
  return (
    <>
      <div className="container4 mt-12">
        <div className="colortext flex m-0 p-0 justify-evenly items-center border-1 border-pinkiess">
          <div className="simple">
            <h3 className="text-2xl font-bold">Browse By</h3>
            <h2 className="cate text-pinkiess font-bold text-2xl">Category</h2>
          </div>
          <button className="vbtn mb-[22px] bg-pinkiess text-white w-[80px] h-[30px] bottom-0 rounded-[3px]">
            View all
          </button>
        </div>
      </div>
      <div className="container5 mt-[5px] flex flex-wrap justify-center gap-10 items-center">
        <div className="flexfood w-[190px] h-[180px] rounded-[5px] translate-x-3 ">
          <img src={category1} width="100%" height="100%" alt="" />
        </div>
        <div className="flexfood w-[190px] h-[180px] rounded-[5px] translate-x-3 ">
          <img src={category2} width="100%" height="100%" alt="" />
        </div>
        <div className="flexfood w-[190px] h-[180px] rounded-[5px] translate-x-3 ">
          <img src={caterogy3} width="100%" height="100%" alt="" />
        </div>
        <div className="flexfood w-[190px] h-[180px] rounded-[5px] translate-x-3 ">
          <img src={category5} width="100%" height="100%" alt="" />
        </div>
        <div className="flexfood w-[190px] h-[180px] rounded-[5px] translate-x-3 ">
          <img src={caterogy6} width="100%" height="100%" alt="" />
        </div>
        <div className="flexfood w-[190px] h-[180px] rounded-[5px] translate-x-3 ">
          {" "}
          <img src={caterogy7} width="100%" height="100%" alt="" />
        </div>
      </div>
    </>
  );
};

export default Category;
