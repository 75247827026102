import React from "react";

function Footer() {
  return (
    <div className="flex justify-center md:my-10 my-5 bg-slate-50 md:py-5 py-5 ">
      Cerbosys 2023 - All right reserved
    </div>
  );
}

export default Footer;
