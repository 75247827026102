import React from "react";
import aboutbg from "../assets/images/aboutbg.png";
import aboutimg from "../assets/images/aboutimg.png";
const About = () => {
  return (
    <>
      <div className="mt-10 w-fit xl:w-[1200px] mx-auto">
        <div>
          <h3 className="text-center font-semibold text-5xl pl-8">
            People Says About Farm
          </h3>
        </div>
        <div className="flex justify-center mt-20">
          <div className="relative w-full h-auto">
            {" "}
            <img
              src={aboutbg}
              alt="img"
              className="h-[900px] md:h-auto min-h-[367px]"
            />
            <div className="absolute  top-[-50px] left-[60px] ">
              <img
                src={aboutimg}
                alt="img"
                className="rounded-full shadow-2xl w-full h-full"
              />
            </div>
            <div className="absolute top-14 left-64">
              <h2 className=" text-2xl font-bold">JHONE DU</h2>
            </div>
            <div className="absolute top-32 md:left-36 right-8 left-20">
              <h2 className="text-lg  text-white ">
                IT is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters,It is a long established fact
                that a reader will be distracted by the readable content of a
                page when looking at its layout. The point of using Lorem Ipsum
                is that it has a more-or-less normal distribution of letters,It
                is a long established fact that a reader will be distracted by
                the readable content of a page when looking at its layout. The
                point of using Lorem Ipsum is that it has a more-or-less normal
                distribution of letters,
              </h2>
            </div>
          </div>
        </div>
        <div className="flex justify-center gap-2 mt-7">
          <div className="w-14 h-4 bg-black rounded-lg"></div>
          <div className="w-14 h-4 bg-Lightyeloow rounded-lg"></div>
          <div className="w-14 h-4 bg-Lightyeloow rounded-lg"></div>
        </div>
      </div>
    </>
  );
};

export default About;
