import React from "react";
import logo from "../assets/logo.png";

const Navbar = () => {
  return (
    <>
      <div className="">
        <div className="flex justify-between w-[100%] bg-cover bg-bgfoodimg relative pl[1%] overflow-hidden">
          <div className="w-[80%]">
            <div className="flex justify-between items-center w-[220px]">
              <div className="w-[60px] pl-[10px] pt-[20px]">
                <img src={logo} width="100%" alt="" />
              </div>
              <h1 className="text-3xl font-bold">FOOD-E</h1>
            </div>
            <h1 className="mt-[90px] text-4xl font-bold">
              Fresh <span className="text-pinkiess">food delivery</span>
              <br />
              from your favorite bistro
              <span className="text-pinkiess">?</span>
            </h1>

            <div className="btns mt-8 flex justify-between w-[230px] h-[35px]">
              <button className="text-white bg-pinkiess rounded-lg w-[110px] items-center bottom-0">
                Order Now
              </button>
              <button className="text-black bg-greyligh w-[110px] bottom-0 rounded-md items-center">
                How it works
              </button>
            </div>
            <div className="flex justify-between mt-[50px] h-[50px] w-[90%] ml-[100px]">
              <div className="p-[5px] flex bg-white pt-[8px] w-[100%]">
                <input
                  className="border-0 w-[100%]"
                  placeholder="Search Products..."
                  type="text"
                  name=""
                  id=""
                />
                <button className="bg-pinkiess h-[30px] bottom-0 w-[50px] rounded-md"></button>
              </div>

              <div className=" h-[50px] bg-white border-0 w-[40%] flex items-center justify-between pt-[10px] pb-[10px] pr-[5px] ml-[20px] rounded-md">
                <label>Select a Category:</label>

                <select
                  name="cars"
                  className=" bottom-0 pt-[10px] h-[10px] mt-[8px]"
                >
                  <option value="volvo"></option>
                </select>
                <button className="tag bg-pinkiess h-[30px] w-[50px] rounded-[3px] bottom-0"></button>
              </div>
            </div>
          </div>

          <div className="background flex justify-center items-center h-fit"></div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
