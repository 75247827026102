// import Home from "./home/Home";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home2 from "./Plants/home2/Plants";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Agricuture from "./Agricuture/home/Agricuture.jsx";
import Foodwear from "./Foodwear/home1/Foodwear";
import Plants from "./Plants/home2/Plants";
import AgricutureInde from "./AgricutureInde";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Notfound from './Notfound'
function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route excat path="/" element={<AgricutureInde />} />
          <Route excat path="/agricuture" element={<Agricuture />} />
          <Route excat path="/order-food-online" element={<Foodwear />} />
          <Route excat path="/plants" element={<Plants />} />
          <Route path="*" element={<Notfound />} />

        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
