import React, { useState } from "react";
import logo from "../assets/logo.png";
import { AiOutlineSearch } from "react-icons/ai";
import cart from "../assets/cart.png";

const Navbar = () => {
  //   const [navbar, setNavbar] = useState("");
  const [navbar, setNavbar] = useState("");
  return (
    <>
      <div>
        <nav className="w-full bg-slate-200 shadow">
          <div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
            <div>
              <div className="flex items-center justify-between py-3 md:py-5 md:block">
                {/* <div className="">
                  <AiOutlineBars />
                </div> */}
                <div className="md:hidden">
                  <button
                    className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                    onClick={() => setNavbar(!navbar)}
                  >
                    {navbar ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 text-white"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 text-white"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                      </svg>
                    )}
                  </button>
                </div>

                <a href="gh" className="flex">
                  <h2 className="text-2xl font-bold text-white">
                    <img src={logo} alt="" />
                  </h2>
                  <h1 className="text-lg font-bold uppercase">Plants</h1>
                </a>
                <div className="md:hidden">
                  <AiOutlineSearch className="text-2xl " />
                </div>
                <div className="md:hidden">
                  <img src={cart} alt="" />
                </div>
              </div>
            </div>
            <div>
              <div
                className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
                  navbar ? "block" : "hidden"
                }`}
              >
                <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
                  <li>
                    <input
                      type="text"
                      name=""
                      value=""
                      className="shadow-lg md:w-[700px] w-96 md:h-10 border-2 rounded-md"
                    />
                  </li>
                </ul>

                {/* <div className="mt-3 space-y-2 lg:hidden md:inline-block">
                  My Account
                </div> */}
              </div>
            </div>

            <div className="hidden space-x-2 md:inline-block">My Account</div>
            <div className="invisible md:visible">
              <img src={cart} alt="" />
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
